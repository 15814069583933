const apps = [
  {
    icon: 'delivery-icon',
    class: 'delivery animated fadeIn',
    title: 'dashboard.deliveryTitle',
    titleInfo: 'dashboard.deliveryTitleInfo',
    url: 'delivery',
    id:1
  },
  {
    icon: 'specialorders-icon',
    class: 'specialorders animated fadeIn',
    title: 'dashboard.specialOrdersTitle',
    titleInfo: 'dashboard.specialOrdersTitleInfo',
    url: 'specialorders',
    id:2
  }
];

const applicationsSelector = () => {
  return apps;
};

export default applicationsSelector;
