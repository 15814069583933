import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store';
import { Provider } from 'react-redux';
import MainApp from './MainApp';
import i18n from './_i18n';

function App({
  language,
  isCustomerSelectorVisible,
  setIsCustomerSelectorVisible,
  isCustomerFilterVisible,
  setIsCustomerFilterVisible
}) {
  i18n.changeLanguage(language);

  return (
    <Provider store={store}>
      <Router>
        <MainApp
          isCustomerSelectorVisible={isCustomerSelectorVisible}
          setIsCustomerSelectorVisible={setIsCustomerSelectorVisible}
          isCustomerFilterVisible={isCustomerFilterVisible}
          setIsCustomerFilterVisible={setIsCustomerFilterVisible}
        />
      </Router>
    </Provider>
  );
}

App.propTypes = {
  language: PropTypes.string.isRequired
};

export default App;
