import { combineReducers } from 'redux';
import initialState from 'store/initialState';
import { GET_TILES_LIST_FOR_USER_TYPE } from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';

const tilesListForUserType = createReducer(
  GET_TILES_LIST_FOR_USER_TYPE,
  initialState.tilesListForUserType
);

export default combineReducers({
  tilesListForUserType
});
