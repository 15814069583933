import { all, put, call, takeLatest } from 'redux-saga/effects';
import { getRequest } from '_http';
import { GET_TILES_LIST_FOR_USER_TYPE } from 'actions/actionTypes';

function* tilesListForUserTypeAsync() {
  try {
    const response = yield call(getRequest, `/user/dashboard-tiles`);
    yield put({ type: GET_TILES_LIST_FOR_USER_TYPE.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: GET_TILES_LIST_FOR_USER_TYPE.FAILURE, payload: error.message });
  }
}

function* watchTilesListForUserTypeAsync() {
  yield takeLatest(GET_TILES_LIST_FOR_USER_TYPE.REQUEST, tilesListForUserTypeAsync);
}

export default function* rootSaga() {
  yield all([
    watchTilesListForUserTypeAsync()
    ]);
}
