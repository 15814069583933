import React, { useEffect } from 'react';
import AppDrawer from 'components/AppDrawer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as dashboardActions from 'actions';

import applicationsSelector from 'selectors';

function Homepage({actions, tilesListForUserType}) {

  useEffect(() => {
    actions.loadDashboardTilesList();
  }, [actions]);
  return (
    
    <React.Fragment>
      <AppDrawer filteredApps={tilesListForUserType.dashboardTiles? applicationsSelector().filter((e)=>{return tilesListForUserType.dashboardTiles.includes(e.id)}) :[]} id="app-drawer" />
      {/* <RecentActivityView /> */}
    </React.Fragment> 
  );
}

function mapStateToProps(state) {
  return {
    tilesListForUserType: state.tilesListForUserType.data
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(dashboardActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);