import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AppGrid from './AppGrid';

export class ApplicationDrawer extends React.Component {
  state = {
    shouldShowInfo: true,
    searchString: ''
  };

  showAppDetails = () => {
    const { shouldShowInfo } = this.state;
    this.setState({ shouldShowInfo: !shouldShowInfo });
  };

  onSearchChange = event => {
    this.setState({ searchString: event.target.value });
  };

  render() {
    const { shouldShowInfo, searchString } = this.state;
    const { filteredApps, t } = this.props;

    return (
      <div className="app-grid">
        <div className="section-header">
          <div className="title">
            <span className="icon fi flaticon-app" />
            <span className="label">{t('dashboard.menu')}</span>
          </div>
          <input
            className="search fi flaticon-magnifying-glass"
            onChange={this.onSearchChange}
            type="search"
            placeholder={t('dashboard.search')}
          />
          <div className="spacer" />
          <div className="info-toggle">
            <input
              type="checkbox"
              id="showInfo"
              onChange={this.showAppDetails}
              className="checkbox"
              checked={shouldShowInfo}
            />
            <label htmlFor="showInfo" className="checkbox-label">
              {t('dashboard.showInfo')}
            </label>
          </div>
        </div>
        {filteredApps.length ? (
          <AppGrid shouldShowInfo={shouldShowInfo} filteredApps={filteredApps} searchString={searchString} />
        ) : null}
      </div>
    );
  }
}

ApplicationDrawer.propTypes = {
  filteredApps: PropTypes.array,
  t: PropTypes.func
};

export default withTranslation()(ApplicationDrawer);
