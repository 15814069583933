import axios from 'axios';
import Cookies from 'universal-cookie';

const baseURL = process.env.REACT_APP_BFF;

const api = axios.create({
  baseURL,
  timeout: 30000,
  withCredentials: true
});

function getHeaders() {
  const headers = { 'Content-Type': 'application/json' };
  if (process.env.REACT_APP_ENV === 'local') {
    const cookies = new Cookies();
    const userClaims = cookies.get('user-claims');
    if (userClaims) headers['x-amzn-oidc-data'] = userClaims;
  }

  return headers;
}

function postRequest(url, data) {
  return api.post(url, data, {
    headers: getHeaders()
  });
}

async function getRequest(url, params = {}) {
  return api.get(url, {
    headers: getHeaders(),
    params
  });
}

function putRequest(url, data) {
  return api.put(url, data, {
    headers: getHeaders()
  });
}

function deleteRequest(url) {
  return api.delete(url, {
    headers: getHeaders()
  });
}

export { postRequest, getRequest, putRequest, deleteRequest, getHeaders };
