import React, { useEffect } from 'react';

import HomePage from 'pages/HomePage';
import AppBar from 'components/AppBar';
import styles from './styles/App.scss';

function MainApp({
  isCustomerSelectorVisible,
  setIsCustomerSelectorVisible,
  isCustomerFilterVisible,
  setIsCustomerFilterVisible
}) {
  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    if (isCustomerSelectorVisible) setIsCustomerSelectorVisible(false);
  }, [isCustomerSelectorVisible, setIsCustomerSelectorVisible]);

  useEffect(() => {
    if (isCustomerFilterVisible) setIsCustomerFilterVisible(false);
  }, [isCustomerFilterVisible, setIsCustomerFilterVisible]);

  return (
    <div className="wrapper dashboard-wrapper">
      <AppBar />
      <HomePage />
    </div>
  );
}

export default MainApp;
