import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

const isAdhereToSearchCriteria = (element, searchString) => {
  const search = searchString.trim();
  if (search === '') {
    return true;
  }
  const regex = new RegExp(search, 'gi');
  const matched = element.title.match(regex) || element.titleInfo.match(regex);
  return matched;
};

export function ApplicationsGrid({ shouldShowInfo, filteredApps, searchString, t }) {
  return (
    <ul className="apps">
      <li className="top-scroll-fader" />
      <li className="bottom-scroll-fader" />
      {filteredApps
        .filter(element => isAdhereToSearchCriteria(element, searchString))
        .map(element => {
          return (
            <Link key={`tile-wrapper${element.class}`} to={element.url} style={{ textDecoration: 'none' }}>
              <li className="tile-wrapper">
                <div key={`tile ${element.class}`} className={classNames('tile hover-brighten', element.class)}>
                  <div key={`icon ${element.class}`} className="icon">
                    <span className={element.icon} />
                  </div>
                  <div key={`divider ${element.class}`} className="divider" />
                  <div key={`title ${element.class}`} className="title">
                    {t(element.title)}
                  </div>
                </div>
                {shouldShowInfo && (
                  <div key={`tile-info ${element.class}`} className="tile-info">
                    {t(element.titleInfo)}
                  </div>
                )}
              </li>
            </Link>
          );
        })}
    </ul>
  );
}

ApplicationsGrid.propTypes = {
  shouldShowInfo: PropTypes.bool,
  filteredApps: PropTypes.array,
  searchString: PropTypes.string,
  t: PropTypes.func
};

export default withTranslation()(ApplicationsGrid);
